import {cva} from 'class-variance-authority';
import React from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer/WistiaVideoPlayer';
import type {WistiaVideoPlayerProps as VideoProps} from '@/components/shared/WistiaVideoPlayer/types';

const col1Styles = cva('', {
  variants: {
    visualPosition: {
      left: '',
      right: '',
    },
    verticalAlign: {
      top: '',
      center: '',
    },
  },
  compoundVariants: [
    {
      visualPosition: 'right',
      className: 'sm:col-span-6',
    },
    {
      visualPosition: 'right',
      verticalAlign: 'center',
      className: 'place-content-center',
    },
  ],
});

const col2Styles = cva('', {
  variants: {
    visualPosition: {
      left: '',
      right: 'order-first md:order-last',
    },
    verticalAlign: {
      top: '',
      center: '',
    },
  },
  compoundVariants: [
    {
      visualPosition: 'left',
      className: 'sm:col-span-6',
    },
    {
      visualPosition: 'left',
      verticalAlign: 'center',
      className: 'place-content-center',
    },
  ],
});

interface SideBySideProps {
  children: React.ReactNode;
  gutter?: 'left' | 'right';
  image?: ImageProps;
  video?: VideoProps;
  mode?: 'light' | 'dark';
  visualPosition?: 'left' | 'right';
  verticalAlign?: 'top' | 'center';
}

export default function SideBySide({
  children,
  gutter,
  image,
  video,
  mode = 'light',
  visualPosition = 'left',
  verticalAlign = 'top',
}: SideBySideProps) {
  function getContent(isVisual: boolean) {
    return isVisual ? (
      video ? (
        <WistiaVideoPlayer {...video} />
      ) : (
        image && (
          <Image {...image} className={twMerge('mx-auto', image.className)} />
        )
      )
    ) : (
      <>
        {React.Children.map(children, (child) => {
          const props = {mode: mode};
          return React.isValidElement(child)
            ? React.cloneElement(child, props)
            : child;
        })}
      </>
    );
  }

  return (
    <TwoColumn>
      <TwoColumn.Col1
        className={col1Styles({
          visualPosition,
          verticalAlign,
        })}
        withGutter={gutter ? gutter === 'left' : visualPosition === 'right'}
      >
        {getContent(visualPosition === 'left')}
      </TwoColumn.Col1>
      <TwoColumn.Col2
        className={col2Styles({
          visualPosition,
          verticalAlign,
        })}
        withGutter={gutter ? gutter === 'right' : visualPosition === 'left'}
      >
        {getContent(visualPosition === 'right')}
      </TwoColumn.Col2>
    </TwoColumn>
  );
}
