import type {ImageProps} from '@/components/base/elements/Image/Image';
import Section from '@/components/base/layouts/Section/Section';
import type {ButtonGroupProps} from '@/components/base/modules/ButtonGroup/ButtonGroup';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import Card, {type CardProps} from '@/components/base/modules/Card/Card';
import SideBySide from '@/components/plus/redesign/modules/SideBySide/SideBySide';
import SectionHeader, {
  type SectionHeaderProps,
} from '@/components/base/sections/SectionHeader/SectionHeader';
import type {WistiaVideoPlayerProps as VideoProps} from '@/components/shared/WistiaVideoPlayer/types';

interface SideBySideListProps {
  buttonGroup?: ButtonGroupProps;
  className?: string;
  componentName?: string;
  image?: ImageProps;
  video?: VideoProps;
  gutter?: 'left' | 'right';
  mode: 'light' | 'dark';
  sectionBottomSpacing?: '2xl' | '4xl';
  sectionHeader?: SectionHeaderProps;
  sectionTopSpacing?: '2xl' | '4xl';
  sideBySideItems?: CardProps[];
  verticalAlign?: 'top' | 'center';
  visualPosition?: 'left' | 'right';
}

export default function SideBySideList({
  buttonGroup,
  className,
  componentName,
  image,
  video,
  gutter = 'right',
  mode = 'light',
  sectionBottomSpacing = '4xl',
  sectionHeader,
  sectionTopSpacing = '4xl',
  sideBySideItems,
  verticalAlign,
  visualPosition = 'left',
}: SideBySideListProps) {
  return (
    <Section
      className={className}
      componentName={componentName}
      bottomSpacing={sectionBottomSpacing}
      mode={mode}
      topSpacing={sectionTopSpacing}
    >
      <SectionHeader mode={mode} {...sectionHeader} />
      <SideBySide
        gutter={gutter}
        image={image}
        video={video}
        mode={mode}
        verticalAlign={verticalAlign}
        visualPosition={visualPosition}
      >
        <div className="grid gap-y-xl mt-4 md:mt-0">
          {sideBySideItems?.map((sideBySideItem, index) => (
            <Card
              key={`card-${index}`}
              mode={mode}
              {...sideBySideItem}
              size="small"
              type="default"
              horizontal={true}
            />
          ))}
          {buttonGroup && (
            <ButtonGroup
              className="sm:ml-[88px] md:ml-24"
              mode={mode}
              {...buttonGroup}
            />
          )}
        </div>
      </SideBySide>
    </Section>
  );
}
